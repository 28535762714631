<template>
  <div class="current-lang-icon" v-if="languages && languages.length>1">
    <a  :id="'menu-activator'+$vnode.key" >
      <img v-if="language!=null" alt="${language}" :src="`/images/languages/lang-${language}.png`">
    </a>
    <v-menu :activator="'#menu-activator'+$vnode.key" :position-x="100">
      <v-list class="lang-menu">
        <v-list-item v-for="lang in languages" :value="lang" v-bind:key="lang">
          <a @click="changelanguage(lang)">
            <img alt="${lang}" :src="`/images/languages/lang-${lang}.png`">
          </a>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import {getLandingPageSettings, getSettings} from "../../scripts/settings";

export default {
  data() {
    return {
      languages: null,
      language: null,
    }
  },

  created() {
    // document.title = this.$t(`gardens.${this.$route.params.garden}.title`);
    this.fetchSettings();
  },
  methods: {
    changelanguage(language) {
      if(this.$route.params.lang === language){
        return;
      }
      let path;
      if (this.$route.fullPath === '/') {
        path = "/" + language;
      }else if(language ==="en" && this.$route.fullPath === '/'+this.$route.params.lang){
        path = "/"
      }else{
        path = this.$route.fullPath.replace(this.$route.params.lang, language);
      }
      this.$router.push(path);
    },
    fetchSettings(){
        this.language = this.$route.params.lang;
      if (this.$route.params.garden) {
        getLandingPageSettings(this.$route.params.garden).then((settings) => {
          this.languages = settings.languages.available;
        }).catch((e)=>{
          this.languages = ['en']});
      }else{
        getLandingPageSettings('corporate').then((settings) => {
          this.languages = settings.languages.available;
        }).catch((e)=>{
          this.languages = ['en']});
      }
    },
  },watch: {
    '$route.params.lang': {
      handler: function(lang) {
        this.language = this.$route.params.lang
      },
      deep: true,
      immediate: true
    }
  }
};
</script>