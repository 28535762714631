<template>
  <div class="rn-pricing-table-area pt--120" id="pricing" v-if="seasonalTicketsPresale">
    <v-container>
      <div class="mt_dec--30">
        <v-row>
          <v-col lg="12">
            <div class="section-title text-center mb--25 mb_sm--0">
              <h2 class="heading-title">{{ $t('headers.pricing')  }}</h2>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col
              lg="6"
              md="6"
              sm="12"
              cols="12"
              class="mt--30"
              key="pricing1">
            <div class="rn-pricing">
              <div class="pricing-table-inner">
                <div class="pricing-header">
                  <h4 class="heading-title color-white" v-if="singleTicketsPresaleActive">{{ $t('pricing.1.presaleTitle')  }}</h4>
                  <h4 class="heading-title color-white" v-else>{{ $t('pricing.1.title')  }}</h4>
                  <div class="pricing color-white">
                    <span class="price color-white" v-if="singleTicketsPresaleActive"><span><s>{{ $t('pricing.1.price')  }}</s></span><span class="discounted-price">{{ $t('pricing.1.presalePrice') }}</span></span>
                    <span class="price color-white" v-else>{{ $t('pricing.1.price')  }}</span>
                    <span class="subtitle color-white">{{ $t('pricing.1.subtitle')  }}</span>
                  </div>
                </div>
                <div class="pricing-body ">
                  <p class="color-white" v-if="singleTicketsPresaleActive">{{ $t('pricing.1.presaleDesc')  }}</p>
                  <p class="color-white" v-else>{{ $t('pricing.1.description')  }}</p>
                </div>
                <div class="pricing-footer">
                  <a class="btn-default btn-hover-white-bg" target="_blank" v-if="activateBuyTicketButtons" :href="$t('ctaUrls.buySingleTicket')">{{ $t('pricing.1.cta')  }}</a>
                </div>
                <div class="pricing-footer mt--10">
                  <a href="javascript: document.getElementById('priceListLink').click();">{{ $t('buttons.seeAllPrices')  }}</a>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
              lg="6"
              md="6"
              sm="12"
              cols="12"
              class="mt--30"
              key="pricing2">
            <div class="rn-pricing">
              <div class="pricing-table-inner">
                <div class="pricing-header">
                  <h4 class="heading-title color-white" v-if="seasonalTicketsPresaleActive">{{ $t('pricing.2.presaleTitle')  }}</h4>
                  <h4 class="heading-title color-white" v-else>{{ $t('pricing.2.title')  }}</h4>
                  <div class="pricing color-white">
                    <span class="price color-white" v-if="seasonalTicketsPresaleActive"><span><s>{{ $t('pricing.2.price')  }}</s></span><span class="discounted-price">{{ $t('pricing.2.presalePrice') }}</span></span>
                    <span class="price color-white" v-else>{{ $t('pricing.2.price')  }}</span>
                    <span class="subtitle color-white">{{ $t('pricing.2.subtitle')  }}</span>
                  </div>
                </div>
                <div class="pricing-body ">
                  <p class="color-white" v-if="seasonalTicketsPresaleActive">{{ $t('pricing.2.presaleDesc')  }}</p>
                  <p class="color-white" v-else>{{ $t('pricing.2.description')  }}</p>
                </div>
                <div class="pricing-footer">
                  <a class="btn-default btn-hover-white-bg" target="_blank" v-if="activateBuySeasonTicketButtons" :href="$t('ctaUrls.buySeasonaleTicket')">{{ $t('pricing.2.cta')  }}</a>
                </div>
                <div class="pricing-footer mt--10">
                  <a href="javascript: document.getElementById('priceListLink').click();">{{ $t('buttons.seeAllPrices')  }}</a>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import feather from "feather-icons";
import {getLandingPageSettings} from "@/scripts/settings";

export default {
  data() {
    return {
      icon: "check",
      singleTicketsPresale: null,
      seasonalTicketsPresale: null,
      singleTicketsPresaleActive: false,
      seasonalTicketsPresaleActive: false,
      activateBuyTicketButtons: false,
      activateBuySeasonTicketButtons: false
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings() {
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        this.singleTicketsPresale = settings.singleTicketsPresale;
        this.seasonalTicketsPresale = settings.seasonalTicketsPresale;
        this.activateBuyTicketButtons = settings.activateBuyTicketButtons;
        this.activateBuySeasonTicketButtons = settings.activateBuySeasonTicketButtons;
        this.setPresale();
      });
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    setPresale(){
      if(this.singleTicketsPresale.active==true){
        var currentDate = new Date().toJSON().slice(0,10) + 'T00:00:00+0200';
        var check = new Date(currentDate);
        var from = new Date(this.seasonalTicketsPresale.periodStart);
        var to   = new Date(this.seasonalTicketsPresale.periodEnd);
        if(check >= from && check <= to){
          this.singleTicketsPresaleActive = true;
        }
      }
      if(this.seasonalTicketsPresale.active==true){
        var currentDate = new Date().toJSON().slice(0,10) + 'T00:00:00+0200';
        var check = new Date(currentDate);
        var from = new Date(this.seasonalTicketsPresale.periodStart);
        var to   = new Date(this.seasonalTicketsPresale.periodEnd);
        if(check >= from && check <= to){
          this.seasonalTicketsPresaleActive = true;
        }
      }
    }
  }
};
</script>