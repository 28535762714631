<template>
  <div>
    <v-navigation-drawer
        class="hidden-lg-and-up header-one-page-nav-drawer demo-page-drawer"
        v-model="drawer"
        fixed
        width="320">
      <v-list-item class="pa-5">
        <div class="logo">
          <img :src="logoDark" class="logo-dark" />
        </div>
        <v-spacer></v-spacer>
        <v-btn
            class="close-icon"
            icon
            @click="drawer = !drawer"
            v-html="iconSvg(closeIcon)">
        </v-btn>
      </v-list-item>
      <scrollactive
          active-class="v-btn--active"
          bezier-easing-value=".5,0,.35,1">
        <v-list>

          <v-list-item
              :ripple="false"
              key="plan"
              to="#plan"
              link
              class="scrollactive-item"
              v-if="activatePlanSection">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.zaplanuj-wizyte') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="map"
              to="#map"
              link
              class="scrollactive-item"
              v-if="activateMapSection">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.mapa') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="attractions"
              to="#attractions"
              link
              class="scrollactive-item"
              v-if="activateAttractionsSection">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.atrakcje') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="pricing"
              to="#pricing"
              link
              class="scrollactive-item"
              v-if="activatePricingSection">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.cennik') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="faq"
              to="#faq"
              link
              class="scrollactive-item"
              v-if="activateFAQSection"
              >
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.faq') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="portfolio"
              to="#portfolio"
              link
              class="scrollactive-item"
              v-if="activateOtherGardensSection">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.inne-wystawy') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
              :ripple="false"
              key="contact"
              to="#contact"
              link
              class="scrollactive-item">
            <v-list-item-content>
              <v-list-item-title>{{ $t('nav.kontakt') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <Languages key="mobile" />

          <!-- End mobile menu sidebar item list -->
        </v-list>
      </scrollactive>
    </v-navigation-drawer>
    <!-- End sidebar mobile menu -->

    <v-app-bar
        dark
        color="transparent"
        fixed
        elevate-on-scroll
        class="header-one-page-nav">
      <a class="logo" @click="toTop">
        <img :src="logoLight" class="logo-light" />
        <img :src="logoDark" class="logo-dark" />
      </a>
      <!-- End brand logo -->
      <v-spacer></v-spacer>

      <!-- End mobile menu icon -->
      <v-toolbar-items class=" height-auto">
        <scrollactive
            active-class="v-btn--active"
            bezier-easing-value=".5,0,.35,1"
            :offset="71"
            class="hidden-xs-only hidden-sm-only hidden-md-only">
          <v-btn
              key="plan"
              to="#plan"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activatePlanSection"
          >{{ $t('nav.zaplanuj-wizyte') }}</v-btn>
          <v-btn
              key="map"
              to="#map"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activateMapSection"
          >{{ $t('nav.mapa') }}</v-btn>
          <v-btn
              key="attractions"
              to="#attractions"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activateAttractionsSection"
          >{{ $t('nav.atrakcje') }}</v-btn>
          <v-btn
              key="pricing"
              to="#pricing"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activatePricingSection"
          >{{ $t('nav.cennik') }}</v-btn>
          <v-btn
              key="faq"
              to="#faq"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activateFAQSection"
          >{{ $t('nav.faq') }}</v-btn>
          <v-btn
              key="portfolio"
              to="#portfolio"
              link
              :ripple="false"
              text
              class="scrollactive-item"
              v-if="activateOtherGardensSection"
          >{{ $t('nav.inne-wystawy') }}</v-btn>
          <v-btn
              key="contact"
              to="#contact"
              link
              :ripple="false"
              text
              class="scrollactive-item"
          >{{ $t('nav.kontakt') }}</v-btn>
        </scrollactive>
        <a class="btn-default btn-border btn-opacity" target="_blank" v-if="activateBuyTicketButtons" :href="$t('ctaUrls.buySingleTicket')">
          <span>{{ $t('buttons.cta') }}</span>
        </a>
        <Languages class="hidden-xs-only hidden-sm-only hidden-md-only" key="desktop" />
      </v-toolbar-items>

      <v-btn
          icon
          :ripple="false"
          class="ma-0 pa-0 hidden-lg-and-up menu_icon"
          @click="drawer = !drawer"
          v-html="iconSvg(icon)">
      </v-btn>
      <!-- End header menu item -->
    </v-app-bar>
    <!-- End top header navbar -->
  </div>
</template>

<script>
import feather from "feather-icons";
import Languages from "@/components/garden-of-lights/Languages.vue";
import {getLandingPageSettings} from "../../scripts/settings";

export default {
  components: {Languages},
  data() {
    return {
      drawer: false,
      icon: "menu",
      garden: this.$route.params.garden,
      closeIcon: "x",
      logoLight: null,
      logoDark: null,
      activateBuyTicketButtons: false,
      activateOtherGardensSection: true,
      activateMapSection: true,
      activateAttractionsSection: true,
      activatePricingSection: true,
      activateCounterSection: true,
      activateFAQSection: true,
      activatePlanSection: true,
      activateServiceSection: true
    }
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {

        if (settings.logo.iconv) {
          let link = document.createElement('link'),
              oldLink = document.getElementById('dynamic-favicon');
          link.id = 'dynamic-favicon';
          link.rel = 'shortcut icon';
          link.href = settings.logo.iconv;
          if (oldLink) {
            document.head.removeChild(oldLink);
          }
          document.head.appendChild(link);
        }

        this.isLoading = false;
        this.logoLight = settings.logo.logoLight;
        this.logoDark = settings.logo.logoDark;
        this.activateBuyTicketButtons = settings.activateBuyTicketButtons;
        this.activateOtherGardensSection = settings.activateOtherGardensSection;
        this.activateMapSection = settings.activateMapSection;
        this.activatePricingSection = settings.activatePricingSection;
        this.activateAttractionsSection = settings.activateAttractionsSection;
        this.activateCounterSection = settings.activateCounterSection;
        this.activateFAQSection = settings.activateFAQSection;
        this.activatePlanSection = settings.activatePlanSection;
        this.activateServiceSection = settings.activateServiceSection;

      });
    },
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },
};
</script>

<style >
.v-btn {
  text-transform: none;
}
</style>
