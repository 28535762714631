<template>
  <div class="about-area ptb--120 bg_color--5" id="faq" v-if="$t('faq').length">
    <div class="about-wrapper">
      <v-container>
        <v-row class="row--35">
          <v-col md="12" cols="12">
            <div class="about-inner inner">
              <div class="section-title">
                <h2 class="heading-title text-center">{{ $t('headers.faq')  }}</h2>
              </div>
              <div class="row mt--30 d-agency-tab">
                <v-col lg="12">
                  <div class="tab-default">
                    <v-tabs v-model="tab" flat hide-slider>
                      <v-tab v-for="(category,i) in $t('faq')" :key="i" :ripple="false">
                        {{category.title}}
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                      <v-tab-item v-for="(category,i) in $t('faq')" :key="i">
                        <div class="single-tab-content">
                          <v-expansion-panels
                              accordion
                              flat
                              mandatory
                              class="about-expension-panels mt--30">
                            <v-expansion-panel v-for="(item, i) in category.questions" :key="i">
                              <v-expansion-panel-header hide-actions>
                                {{item.question}}
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <p v-html="item.answer"></p>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </div>
                      </v-tab-item>
                    </v-tabs-items>
                  </div>
                </v-col>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import feather from "feather-icons";
import Accordion from "@/components/accordion/Accordion.vue";
import {getLandingPageSettings} from "@/scripts/settings";
export default {
  components: {Accordion},
  data() {
    return {
      tab: null,
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
      });
    },
  },
};
</script>