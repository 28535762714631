<template>
  <div class="sticky-container">
    <ul class="sticky">
      <li v-for="(social, i) in socialList" :key="i" v-if="social.url">
        <a :href="social.url" target="_blank" v-if="social.iconUrl">
          <img width="32" height="32" :src="social.iconUrl"  />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Contact from "@/components/garden-of-lights/Contact.vue";
import {getLandingPageSettings} from "@/scripts/settings";
import feather from "feather-icons";

export default {
  components: {Contact},
  data() {
    return {
      socialList: [],
    };
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        this.socialList = settings.socialList;
      });
    },
  },
};
</script>
