<template>
  <div>
    <!-- Start Header Area -->
    <HeaderCorporate />
    <!-- End Header Area -->

    <!-- Start Banner Area  -->
    <Banner />
    <!-- End Banner Area  -->

    <!-- Start Service Area  -->
    <ServiceCorporate />
    <!-- End Service Area  -->

    <!-- Start Portfolio Area -->
    <!-- <GardensInAllCountries /> -->
    <!-- End Portfolio Area -->

    <!-- Start Footer Area  -->
    <FooterCorporate id="contact" />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import FooterCorporate from "@/components/garden-of-lights/FooterCorporate.vue";
import {getLandingPageSettings, getSettings} from "@/scripts/settings";
import GardensInAllCountries from "@/components/garden-of-lights/GardensInAllCountries.vue";
import HeaderCorporate from "@/components/garden-of-lights/HeaderCorporate.vue";
import ServiceCorporate from "@/components/garden-of-lights/ServiceCorporate.vue";
import Banner from "@/components/garden-of-lights/Banner.vue";

export default {
  components: {
    Banner,
    HeaderCorporate,
    ServiceCorporate,
    GardensInAllCountries,
    FooterCorporate
  },
  data() {
    return {
    };
  },
  mounted() {
    this.fetchSettings();
    //document.title = this.$t(`meta.title`);
  },
  methods: {
    fetchSettings(){
      let landigpageName = "corporate";
      getLandingPageSettings(landigpageName).then((settings) => {
        if (settings.gardensContent.length === 1) {
          this.$router.push('/' + settings.gardensContent[0].path);
        }
      });
    },
  },
};

</script>