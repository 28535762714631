<template>
  <div>
    <VueSlickCarousel
        v-bind="settings"
        class="slider-digital-agency slider-activation rn-slick-dot dot-light mb--0 color-white"
        v-if="sliderContent.length">
      <div
          class="slide slide-style-2 fullscreen d-flex align-center bg_image"
          data-black-overlay="2"
          v-for="(slider, i) in sliderContent"
          :key="i"
          :style="{ backgroundImage: 'url(' + slider.src + ')' }"
      >
        <v-container>
          <v-row>
            <v-col cols="12">
              <div class="text-center inner">
                <h1 class="heading-title" v-if="slider.viewTitle">{{ $t('slider.' + slider.id + '.title')  }}</h1>
                <p class="description" v-if="slider.viewDesc">
                  {{ $t('slider.' + slider.id + '.desc')  }}
                </p>
                <div class="slide-btn" v-if="slider.viewButton">
                  <a class="btn-default" target="_blank" :href="slider.url">{{ $t('slider.' + slider.id + '.button')  }}</a>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <!-- End Single Slide  -->
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { getLandingPageSettings} from "../../scripts/settings";

export default {
  components: { VueSlickCarousel },
  data() {
    return {
      sliderContent: [],
      settings: {
        fade: true,
        dots: true,
        arrows: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        margin: 20,
        adaptiveHeight: true,
      },
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        this.sliderContent = settings.sliderContent;
      });
    },
  },
};
</script>

<style lang="scss">
.slick-slide {
  img {
    display: block;
    width: 100%;
  }
}
</style>
