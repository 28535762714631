<template>
  <div class="youtube_video pb--120 bg_color--1" v-if="showYTVideo">
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <div v-html="$t('youtubeVideo.iframe')"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {getLandingPageSettings} from "@/scripts/settings";

export default {
  components: {
  },
  data() {
    return {
      showYTVideo: false,
    };
  },
  mounted() {
    this.fetchSettings();
    if(this.$t('youtubeVideo.iframe') && this.$t('youtubeVideo.iframe')!='' && this.$t('youtubeVideo.iframe')!='youtubeVideo.iframe'){
      this.showYTVideo = true;
    }
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {

      });
    },
  },
};
</script>