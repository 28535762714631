<template>
  <footer class="footer-area footer-style-01 bg_color--6">
    <div class="im-call-to-action-area ptb--70 im-separator">
      <Contact />
    </div>
    <div class="footer-wrapper ptb--70">
      <v-container class="container">
        <v-row>
          <v-col lg="3" md="6" sm="6" cols="12">
            <div class="footer-logo">
              <a href="/"><img :src="logoFooter" alt="brand image"/></a>
            </div>
          </v-col>
          <v-col lg="3" md="6" sm="6" cols="12" class="mt_mobile--40">
            <div class="footer-link">
              <h4>{{ $t('footer.address.header') }}</h4>
              <div v-html="$t('footer.address.content')" class="white--text"></div>
            </div>
          </v-col>
          <v-col lg="3" md="6" sm="6" cols="12" class="mt_md--40 mt_sm--40">
            <div class="footer-link">
              <h4>{{ $t('footer.contact.header') }}</h4>
              <div v-html="$t('footer.contact.contentParagraph1')" class="white--text"></div>
            </div>
          </v-col>
          <v-col lg="3" md="6" sm="6" cols="12" class="mt_md--40 mt_sm--40">
            <div class="footer-link">
              <h4>{{ $t('footer.openingHours.header') }}</h4>
              <div v-html="$t('footer.openingHours.content')" class="white--text"></div>
            </div>
          </v-col>
        </v-row>
        <v-row class="mt--20">
            <div class="white--text col-sm-12">
              Copyright © {{ new Date().getFullYear() }} {{ $t('copyrightText') }}
            </div>
        </v-row>
      </v-container>
    </div>
  </footer>
</template>

<script>
  import Contact from "@/components/garden-of-lights/Contact.vue";
  import {getLandingPageSettings} from "@/scripts/settings";
  import feather from "feather-icons";

  export default {
    components: {Contact},
    data() {
      return {
        logoFooter: null,
      };
    },
    created() {
      this.fetchSettings();
    },
    methods: {
      fetchSettings(){
        let landigpageName = "corporate";
        if (this.$route.params.landingpage == "poland") {
          landigpageName = "poland"
        }
        getLandingPageSettings(landigpageName).then((settings) => {
          this.logoFooter = settings.logo.logoFooter;
        });
      },
    },
  };
</script>
