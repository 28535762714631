<template>
  <div class="rn-brand-area ptb--120 bg_color_map" id="map">
    <v-container>
      <v-row>
        <v-col lg="12">
          <div class="section-title text-center mb--25 mb_sm--0">
            <h2 class="heading-title color-white">{{ $t('headers.map')  }}</h2>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt--30">
        <v-col lg="12">
          <div class="thumb position-relative">
            <div class="thumbnail position-relative pointer" @click="index = 0">
              <v-lazy-image :src="$t('map.src')" class="w-100"  />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="$t('map.text')">
        <v-col lg="12" class="map-info-text" >
          <div v-html="$t('map.text')" class="text-justify" />
        </v-col>
      </v-row>
      <div v-if="mapContent">
        <CoolLightBox
            :items="mapContent"
            :index="index"
            :fullScreen="true"
            :effect="'fade'"
            @close="index = null">
        </CoolLightBox>
      </div>
    </v-container>
  </div>
</template>

<script>
import {getLandingPageSettings} from "../../scripts/settings";
import VLazyImage from "v-lazy-image/v2";

export default {
  components: {VLazyImage},
  data() {
    return {
      index: null,
      mapContent: null
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {});
    },
  }, watch: {
    '$i18n.messages': {
      handler: function (lang) {
        this.mapContent = [
          {
            "src": this.$t('map.src')
          }
        ]
      },
      deep: true,
      immediate: true
    }
  }
};
</script>