<template>
  <div class="rn-portfolio-area pt--60 pb--120 bg_color--1 line-separator" id="attractions"  v-if="galleryContent.length">
    <v-container>
      <v-row>
        <v-col lg="12">
          <div class="section-title text-center mb--25 mb_sm--0">
            <h2 class="heading-title">{{ $t('headers.attractions')  }}</h2>
          </div>
        </v-col>
      </v-row>
      <v-row class="gallery-wrapper gallery-grid" id="animated-thumbnials" >
        <!-- Start Single Gallery -->
        <div class="item-portfolio-static gallery col-md-6 col-sm-6"
           v-for="(image, imageIndex) in galleryContent"
           :key="imageIndex">
          <div class="portfolio-static">
            <div class="thumbnail-inner image" @click="index = imageIndex">
              <div class="thumbnail">
                <!--<v-lazy-image :src="image.thumb"  /> // powoduje problem ze scrollowaniem-->
                <img :src="image.thumb"  />
              </div>
            </div>
            <div class="content">
              <div class="inner galleryImageDescLead">
                <h4>{{ $t('gallery.' + image.id + '.title')  }}</h4>
                <p v-html="$t('gallery.' + image.id + '.tag')"></p>
                <p><a @click="showMore(image.id)" v-if="!readMore[image.id]">{{ $t('buttons.readMore')  }}</a></p>
                <p v-html="$t('gallery.' + image.id + '.tagMore')" v-if="readMore[image.id]"></p>
              </div>
            </div>
          </div>
        </div>
        <!-- End Single Gallery -->
      </v-row>
      <CoolLightBox
          :items="galleryContent"
          :index="index"
          :fullScreen="true"
          :effect="'fade'"
          @close="index = null">
      </CoolLightBox>
    </v-container>
  </div>
</template>
<script>
import { getLandingPageSettings} from "../../scripts/settings";
import VLazyImage from "v-lazy-image/v2";
export default {
  components: {VLazyImage},
  data() {
    return {
      galleryContent: [],
      readMore: {},
      index: null,
    };
  },
  mounted() {
    this.fetchSettings();
  },
  methods: {
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        this.galleryContent = settings.galleryContent;
      });
    },
    showMore(id) {
      this.$set(this.readMore, id, true);
    },
  },
};
</script>