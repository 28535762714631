<template>
  <div class="service-area creative-service-wrapper pt--30 pb--120 bg_color--5">
    <v-container>
      <v-row class="service-one-wrapper text-center" v-if="this.serviceContent[0]">

        <v-col
            xl="4"
            lg="4"
            md="4"
            sm="6"
            cols="12"
            class="mt--30"
            key="service0">
            <div class="service service__style--1" >
              <a @click="sendEventToParent(0)">
                <div class="icon">
                  <img :src="this.serviceContent[0].src" />
                </div>
                <div class="content mt--20">
                  <h3 class="heading-title">
                    {{ $t('service.' + this.serviceContent[0].id + '.title')  }}
                  </h3>
                  <p v-html="$t('service.' + this.serviceContent[0].id + '.description')" />
                </div>
              </a>
            </div>
        </v-col>

        <v-col
            xl="4"
            lg="4"
            md="4"
            sm="6"
            cols="12"
            class="mt--30"
            key="service1">
          <div class="service service__style--1">
            <a @click="sendEventToParent(1)">
              <div class="icon">
                <img :src="this.serviceContent[1].src" />
              </div>
              <div class="content mt--20">
                <h3 class="heading-title">
                  {{ $t('service.' + this.serviceContent[1].id + '.title')  }}
                </h3>
                <p>{{currentDateText}}<br/>{{isItOpenTodayText}}<br/>{{openingHoursTodayText}}</p>
              </div>
            </a>
          </div>
        </v-col>
        <v-col
            xl="4"
            lg="4"
            md="4"
            sm="6"
            cols="12"
            class="mt--30"
            key="service2">
            <div class="service service__style--1">
              <a @click="sendEventToParent(2)" id="priceListLink">
                <div class="icon">
                  <img :src="this.serviceContent[2].src" />
                </div>
                <div class="content mt--20">
                  <h3 class="heading-title">
                    {{ $t('service.' + this.serviceContent[2].id + '.title')  }}
                  </h3>
                  <p v-html="$t('service.' + this.serviceContent[2].id + '.description')" />
                </div>
              </a>
            </div>
        </v-col>
      </v-row>
      <v-row v-if="activateInfoSection">
        <v-col lg="12" class="mission info-text">
          <div v-if="$t('info.header')" class="section-title mb--30"><h2 v-html="$t('info.header')" class="text-center heading-title mb--30 pt--90" /></div>
          <div v-if="$t('info.text')" v-html="$t('info.text')" class="text-justify" />
        </v-col>
      </v-row>
      <v-row>
      <v-col lg="12" class="mission" >
          <div class="section-title mb--30"><h2 v-html="$t('mission.header')" class="text-center heading-title mb--30 pt--90" /></div>
          <div v-html="$t('mission.lead')" class="text-justify" />
          <div class="text-center" v-if="$t('mission.restOfText')">
            <a @click="showMoreDescClick()" v-if="showMoreDesc" class="btn-default btn-border mt--30">{{ $t('buttons.readMore')  }}</a>
          </div>
      </v-col>
      </v-row>
      <v-row v-if="!showMoreDesc">
        <v-col lg="12">
          <div class="description text-justify mt--30" v-html="$t('mission.restOfText') "></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {getLandingPageSettings} from "@/scripts/settings";

export default {
  data() {
    return {
      showMoreDesc: true,
      serviceContent: [],
      openingStateSettings: null,
      currentDateText: "",
      isItOpenTodayText: "",
      openingHoursTodayText: "",
      infoSectionSettings: null,
      activateInfoSection: false
    };
  },
  created() {
    this.fetchSettings();
  },
  methods: {
    showMoreDescClick(){
      this.showMoreDesc = !this.showMoreDesc;
    },
    sendEventToParent(id){
      this.$emit("openModal", this.$t('service.' + this.serviceContent[id].id + '.titleExtended'), this.$t('service.' + this.serviceContent[id].id + '.descriptionExtended'));
    },
    test(){
      alert('test');
    },
    fetchSettings(){
      getLandingPageSettings(this.$route.params.garden).then((settings) => {
        this.serviceContent = settings.serviceContent;
        this.showMoreDesc = settings.leadShowMore;
        this.openingStateSettings = settings.openingState;
        this.infoSectionSettings = settings.infoSection;

        this.setInfoSection();
        this.setInfoIfOpen();
      });
    },
    setInfoSection(){
      if(this.infoSectionSettings.active==true){
        var check = new Date();
        var from = new Date(this.infoSectionSettings.periodStart);
        var to   = new Date(this.infoSectionSettings.periodEnd);
        if(check >= from && check <= to){
          this.activateInfoSection = true;
        }
      }
    },

    setInfoIfOpen(){
      let today = new Date();
      this.currentDateText = this.formatDate(today);

      if (!this.openingStateSettings){
        this.setClosed();
        return;
      }

      if(this.checkIsNotWorkingDay(this.currentDateText)){
        return;
      }

      if (this.checkIsExceptionDay(this.currentDateText)){
        return;
      }

      if (this.checkInPeriods(today)){
        return;
      }

      this.setClosed();
    },

    checkIsNotWorkingDay(date){
      if(this.openingStateSettings.notWorkingDays == null){
        return false;
      }

      if(this.openingStateSettings.notWorkingDays.includes(date)){
        this.setClosed();
        return true;
      }

      return false;
    },

    checkIsExceptionDay(date){
      if(this.openingStateSettings.exceptionDays == null){
        return false;
      }

      let obj = this.openingStateSettings.exceptionDays;
      let flag = false;
      let exceptionDay = {};

      Object.keys(obj).forEach(function (key){
        if(date == obj[key].date){
          flag = true;
          exceptionDay = {
            hourFrom: obj[key].hourFrom,
            hourTo: obj[key].hourTo
          };
        }
      });

      if(flag){
        this.setOpened(exceptionDay.hourFrom, exceptionDay.hourTo);
      }

      return flag;
    },

    checkInPeriods(date){
      if(this.openingStateSettings.periods == null){
        return false;
      }

      let obj = this.openingStateSettings.periods;
      let dayName = this.getDayName(date);
      let flag = false;
      let period = {};

      Object.keys(obj).forEach(function (key){
        let dateFrom = new Date(obj[key].dateFrom);
        let dateTo = new Date(obj[key].dateTo);

        if(date >= dateFrom && date <= dateTo && obj[key].days.includes(dayName)){
          flag = true;
          period = {
            hourFrom: obj[key].hourFrom,
            hourTo: obj[key].hourTo
          }
        }
      });

      if(flag){
        this.setOpened(period.hourFrom, period.hourTo);
      }

      return flag;
    },

    formatDate(date){
      return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, "0") + '-' + String(date.getDate()).padStart(2, "0");
    },

    formatOpeningHours(hourFrom, hourTo){
      return "(" + hourFrom + " - " + hourTo + ")";
    },

    getDayName(date){
      return date.toLocaleDateString('en-EN', { weekday: 'long' });
    },
    
    setOpened(hourFrom, hourTo){
      this.isItOpenTodayText = this.$t('openingHoursInfo.itsOpenToday');
      this.openingHoursTodayText = this.formatOpeningHours(hourFrom, hourTo);
    },

    setClosed(){
      this.isItOpenTodayText = this.$t('openingHoursInfo.itsClosedToday');
    },
    
  },watch: {
    '$i18n.messages': { //gdy tylko zmieni sie cokolwiek w tłumaczeniu to uruchom poniższa funkcje
      handler: function() {
        this.setInfoIfOpen();
      },
      deep: true,
      immediate: true
    }
  }
};

</script>
<style>

</style>